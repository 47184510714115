import React from 'react';
import styles from '@components/IconGrid/styles/iconGrid.module.scss';
import { IconTypeEnum, IIcon } from '@components/IconGrid/types';
import { Typography } from '@components/Typography';
import { HeadingStyles, HeadingTags } from '@components/Typography/Heading/types';
import Image from '@components/Image';

const IconItem = ({ alt, description, fileReference, text, title, type }: IIcon) => {
    return (
        <div className={styles.icon}>
            {type === IconTypeEnum.IMAGE && fileReference && (
                <Image
                    image={{
                        alt,
                        dimensionsType: 'defined',
                        fileReference,
                        height: '32px',
                        width: '32px',
                    }}
                    classes={{
                        image: styles.image,
                    }}
                />
            )}
            <div className={styles.content}>
                {type === IconTypeEnum.TEXT && (
                    <Typography.Heading style={HeadingStyles.H1} className={styles.textIcon}>
                        {text}
                    </Typography.Heading>
                )}
                {title && (
                    <Typography.Heading style={HeadingStyles.H4} tag={HeadingTags.H4}>
                        {title}
                    </Typography.Heading>
                )}
                <Typography.Body>{description}</Typography.Body>
            </div>
        </div>
    );
};

export default IconItem;
