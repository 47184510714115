export interface ISpacing {
    paddingClass: string;
}

export enum AlignmentEnum {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
}

export enum ThemeEnum {
    MUSTO = 'musto',
    HHECOMM = 'hhecomm',
    HHWORKWEAR = 'hhworkwear',
}
