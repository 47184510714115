'use client';

import React, { PureComponent } from 'react';

export default class ErrorBoundary extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        const { hasError } = this.state;
        const { children, className, message } = this.props;

        if (hasError) {
            return this.message ? <div className={className}>{message}</div> : null;
        }

        return children;
    }
}
