import React, { ReactElement } from 'react';
import type { IVisibilityProps } from './types';
import { useAemContext } from '@components/AemRender/useAemContext';

const Visibility = ({ children, visibility }: IVisibilityProps): ReactElement | null => {
    const { isMobile } = useAemContext();
    const { editMode, enabled, hideOnDesktop, hideOnMobile } = visibility || {};
    const isHidden = enabled && (isMobile ? hideOnMobile : hideOnDesktop);

    return isHidden && !editMode ? null : <>{children}</>;
};

export default Visibility;
