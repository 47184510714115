import React, { createContext, ReactElement, useContext } from 'react';
import { IAemContext, IAemContextProviderProps } from '@components/AemRender/types';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AemContext = createContext<IAemContext>({});

export const AemContextProvider = ({ children, isMobile, theme }: IAemContextProviderProps): ReactElement => {
    const storeConfig = {
        isMobile,
        theme,
    };

    return <AemContext.Provider value={storeConfig}>{children}</AemContext.Provider>;
};

export const useAemContext = () => useContext(AemContext);
