'use client';

import React, { ReactElement, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper/modules';
import AemLink from '@components/AemLink';
import { IconAlignEnum, IconRenditionEnum, IIconGrid, IIconWrapper } from './types';
import 'swiper/css/bundle';
import styles from './styles/iconGrid.module.scss';
import { BREAKPOINT_M } from '@utils/breakpoints';
import clsx from 'clsx';
import IconItem from '@components/IconGrid/IconItem';

const IconWrapper = ({ children, link }: IIconWrapper): ReactElement =>
    link ? (
        <AemLink className={styles.link} to={link}>
            {children}
        </AemLink>
    ) : (
        children
    );

const IconGrid = ({ align, enableCarouselInMobile, items, rendition, type, uuid }: IIconGrid): ReactElement => {
    const rootClasses = clsx(
        styles.root,
        align === IconAlignEnum.CENTER && styles.alignedCenter,
        rendition === IconRenditionEnum.SLIM && styles.slim,
    );

    const icons = useMemo(() => {
        return items.map((icon, index) => {
            const { linkUrl, ...rest } = icon;

            return (
                <IconWrapper key={index} link={linkUrl}>
                    <IconItem {...rest} type={type} />
                </IconWrapper>
            );
        });
    }, [align, type, items]);

    const breakpoints = {
        0: {
            slidesPerView: align === IconAlignEnum.CENTER ? 1 : 1.3,
        },
        [BREAKPOINT_M]: {
            enabled: false,
        },
    };

    const handleSwiperInit = (swiper) => {
        if (!(swiper.currentBreakpoint === '0' && enableCarouselInMobile)) {
            swiper.destroy(false, true);
        }
    };

    return (
        <div className={rootClasses} data-mt-type={uuid}>
            <Swiper
                modules={[Scrollbar]}
                style={{
                    '--swiper-scrollbar-bottom': '0px',
                }}
                scrollbar={{
                    draggable: true,
                }}
                breakpoints={breakpoints}
                onSwiper={handleSwiperInit}
                spaceBetween={32}
            >
                {icons.map((icon: ReactElement, index: number) => (
                    <SwiperSlide key={index} className={styles.swiperSlide}>
                        {icon}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default IconGrid;
