import React, { ReactElement } from 'react';
import clsx from 'clsx';
import shallowMerge from '@utils/shallowMerge';
import AemLink from '@components/AemLink';
import Visibility from '@components/Visibility';
import { AlignmentEnum } from '../../types';
import type { IImage, IImageComponentProps, IParallax } from './types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import defaultClasses from './styles/image.module.scss';
import { getRelativeUrlPath } from '@utils/getRelativeUrlPath';

const Parallax = ({ classes, fileReference, height }: IParallax): ReactElement => {
    return (
        <div className={classes.parallax} style={{ height: `${height}px` }}>
            <div className={classes.parallaxBox}>
                <div className={classes.parallaxImage} style={{ backgroundImage: `url(${fileReference})` }} />
            </div>
        </div>
    );
};

const sanitizeRestProps = (propsObject) => {
    // Remove all custom (non html) attributes
    const { mappedImageAlignmentClass, mappedImageDimensionsTypeClass, ...rest } = propsObject;

    return rest;
};

const ImageComponent = ({
    alignment,
    alt,
    caption,
    credit,
    dimensionsType = 'defined',
    fileReference,
    fileReferenceMobile,
    height,
    loading,
    placeholder,
    propsClasses,
    sizes,
    srcSet,
    width,
    ...rest
}: IImageComponentProps): ReactElement => {
    const dimensions = (() => {
        type dimensionsMappingType = {
            [key in typeof dimensionsType]?: string | number | null;
        };

        const heightMapping: dimensionsMappingType = {
            defined: height,
        };

        const widthMapping: dimensionsMappingType = {
            defined: width,
            full: '100%',
        };

        return {
            height: heightMapping[dimensionsType] || null,
            width: widthMapping[dimensionsType] || null,
        };
    })();

    // TODO consider to use picture tag with media queries instead of having 2 pictures
    const image = (
        <>
            {fileReferenceMobile && (
                <LazyLoadImage
                    alt={alt}
                    effect="blur"
                    loading={loading}
                    placeholderSrc={placeholder}
                    sizes={sizes}
                    src={fileReferenceMobile}
                    srcSet={srcSet}
                    wrapperClassName={clsx(propsClasses.image, propsClasses[alignment], defaultClasses.hideOnDesktop)}
                    {...dimensions}
                    {...sanitizeRestProps(rest)}
                />
            )}
            <LazyLoadImage
                alt={alt}
                effect="blur"
                loading={loading}
                placeholderSrc={placeholder}
                sizes={sizes}
                src={fileReference}
                srcSet={srcSet}
                wrapperClassName={clsx(
                    propsClasses.image,
                    propsClasses[alignment],
                    fileReferenceMobile && defaultClasses.hideOnMobile,
                )}
                {...dimensions}
                {...sanitizeRestProps(rest)}
            />
        </>
    );

    return caption || credit ? (
        <figure className={propsClasses.wrapper}>
            {image}
            {caption ? (
                <figcaption className={clsx(propsClasses.caption, propsClasses[alignment])}>
                    {caption}
                    {credit ? <span className={propsClasses.credit}>{credit}</span> : null}
                </figcaption>
            ) : null}
        </figure>
    ) : (
        <div className={propsClasses.wrapper}>{image}</div>
    );
};

const Image = ({ classes: propsClasses, image, visibility }: IImage): ReactElement | null => {
    const {
        alignment = AlignmentEnum.LEFT,
        alt,
        caption,
        credit,
        dimensionsType,
        fileReference,
        fileReferenceMobile,
        height,
        parallax,
        url,
        width,
        ...rest
    } = image || {};

    if (!fileReference) {
        return null;
    }
    const classes = shallowMerge(defaultClasses, propsClasses);

    const relativeUrlPath = getRelativeUrlPath(fileReference) as string;

    const componentWithParallax = parallax ? (
        <Parallax classes={classes} height={height} width={width} fileReference={relativeUrlPath} />
    ) : (
        <ImageComponent
            alignment={alignment}
            alt={alt}
            caption={caption || ''}
            propsClasses={classes}
            credit={credit || ''}
            dimensionsType={dimensionsType}
            fileReference={relativeUrlPath}
            fileReferenceMobile={getRelativeUrlPath(fileReferenceMobile)}
            height={height}
            width={width}
            {...sanitizeRestProps(rest)}
        />
    );

    if (url && typeof url === 'string') {
        return (
            <Visibility visibility={visibility}>
                <AemLink className={classes.link} to={url}>
                    {componentWithParallax}
                </AemLink>
            </Visibility>
        );
    }

    return <Visibility visibility={visibility}>{componentWithParallax}</Visibility>;
};

export default Image;
