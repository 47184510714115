import type { ReactElement } from 'react';
import type { IVisibility } from '@components/Visibility/types';
import { IAemComponent } from '@components/PageRenderer/v2/types';

export enum IconTypeEnum {
    TEXT = 'text',
    IMAGE = 'image',
}

export enum IconRenditionEnum {
    DEFAULT = 'default',
    SLIM = 'slim',
}

export enum IconAlignEnum {
    CENTER = 'center',
    LEFT = 'left',
}

export interface IIconWrapper {
    link?: string;
    children: ReactElement;
}

export interface IIcon {
    fileReference?: string;
    alt: string;
    text?: string;
    title?: string;
    description: string;
    linkUrl?: string;
    type: IconTypeEnum;
}

export interface IIconGrid extends IVisibility {
    type: IconTypeEnum;
    rendition: IconRenditionEnum;
    align: IconAlignEnum;
    enableCarouselInMobile: boolean;
    items: IIcon[];
    uuid?: string;
}

export interface IIconGridAem extends Omit<IIconGrid, 'enableCarouselInMobile'>, IVisibility, IAemComponent {
    mobileView: boolean;
}
